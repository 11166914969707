.container {
  width: 100vw;
  min-height: 100vh;
  background-color: black;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero {
  width: 100vw;
}
.welcome {
  margin-top: 3em;
  margin-bottom: 3em;
  font-size: 1.75em;
  text-align: center;
  display: flex;
  align-items: center;
}

.welcomeBildet {
  width: 33%;
  padding-left: 20vw;
}

.bildet {
  width: 300px;
}

.welcomeTekst {
  width: 67%;
  padding-left: 40px;
  padding-right: 20vw;
  text-align: left;
}

.welcomeTekst p {
  font-size: 1.1rem;
}

.welcomeTekst h1 {
  margin-top: 0px;
}

.Year {
  font-size: 1.5em;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.Year:hover {
  border-bottom: 3px solid royalblue;
}

.active {
  border-bottom: 3px solid royalblue;
}

.navbar {
  margin-top: 2em;
  position: absolute;
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navlinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2em;
  width: 70%;
  font-size: 1.15em;
  text-transform: uppercase;
  cursor: pointer;
}
.navlinksMobileIcon {
  display: none;
}
.navlinksMobile {
  display: none;
}
.navlinks p {
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
}
.navlinks p:hover {
  border-bottom: 3px solid royalblue;
}

.chooseYear {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  margin-top: 2em;
}

.projects {
  display: grid;
  grid-template-columns: 33.33vw 33.33vw 33.33vw;
  gap: 0;
  overflow: hidden;
}

.contact {
  border-top: 2px solid grey;
  width: 100vw;
  margin-top: 4em;
  padding-top: 4em;
  display: grid;
  grid-template-columns: 33vw 33vw 33vw;
  gap: 0;
  padding-bottom: 0;
}
.contactItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactItem:first-child {
  align-items: flex-start;
  padding-left: 12vw;
}
.contactItem:last-child {
  align-items: flex-end;
  padding-right: 12vw;
}
.contactItem p {
  width: fit-content;
  text-align: center;
}
.contactItem:first-child p {
  text-align: left;
}
.contactItem:last-child p {
  text-align: right;
}
.contactItem img {
  margin-bottom: 1em;
}
.contactItem p {
  margin-top: 0;
  line-height: 1em;
}
.contactLink {
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.contactLink:hover {
  border-bottom: 2px solid royalblue;
}

.c {
  color: gray;
}
.closeBtn {
  width: 50vw;
  display: flex;
  justify-content: flex-end;
}
.videoPlayer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000000;
}
.videoPlayer video {
  width: 50vw;
}

.hrSplitter {
  background-color: white;
  width: 40%;
  height: 1.5px;
}

.overlayDiv {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: x-large;
  backdrop-filter: blur(1.5px);
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.hiddenMobile {
  display: flex;
}

.dialog {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000fd;
  color: white;
  border: none;
  z-index: 1000000;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .hero {
    width: 250vw;
  }

  .navlinks {
    display: none;
  }

  .navlinksMobileIcon {
    display: flex;
    margin-right: 3vh;
    z-index: 1000;
  }
  .navlinksMobile {
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 17em;
    width: 100vw;
    height: 100vh;
    top: -20px;
    background-color: rgba(40, 40, 40, 0.99);
    margin-top: -1em;
  }
  .navlinksMobile p {
    line-height: 0.1em;
    font-size: 2em;
    text-transform: uppercase;
  }
  .projects {
    display: grid;
    grid-template-columns: 100vw;
    gap: 0;
  }

  .hiddenMobile {
    display: none;
  }
  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
