@font-face {
	font-family: OsloFont;
	src: url("../OsloFont/OsloSans-Regular.otf");
}

body {
	user-select: none;
	margin: 0;
	color: white;
	overflow-x: hidden;
	overflow-y: scroll;
	font-family: OsloFont;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

::-webkit-scrollbar {
	display: none;
}

.hidden {
	display: none;
}
